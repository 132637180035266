import Clipboard from 'clipboard'
import h5AppLink from './h5AppLink';
export default function handleClipboard(text,event, type){
  const clipboard =  new Clipboard(event.target,{
    text:()=>text
  })
  clipboard.on('success',() => {
    if (!type){
      h5AppLink.h5AppLink('cacheCopy', {content: text})
    }
    clipboard.destroy()
  })
  clipboard.on('error',() =>{
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
