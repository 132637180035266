/**
 * Created by longji on 2020/9/3.
 */
export const api = {
  activity: '/activity',                             // 活动列表
  whale: '/user/statistic/whale',                    // 团长特权
  superCashback: '/item/activity/bonus/query',       // 超级补贴
  activityDetails: '/item/activity/query',           // 京东专区活动
  articleLike: '/content/article/like',              // 文章点赞
  activityInfo: '/position/activity/info',           // 获取活动信息
  goodsSearch: '/item/search',                       // 超级补贴下搜索商品
  article: '/content/article/one',                   // 文章
  problems: '/content/article/query',                // 常见问题列表
  transfer: '/item/expand/transfer',                 // 外卖转链
  goodsJump: '/item/promotion',                 // 商品详情页转链(跳转tb场景)
  goodsTransfer: '/item/promotion/share',                 // 商品详情页转链(分享场景)
  auth: '/user/auth/url',                             // 外卖授权
  strategyUrl: '/item/expand/transfer',               // 拓展平台转链与分享
  activityMaterials: '/item/tb/activity/materials',               // 淘宝官方物料id集合获取
  activityMaterialsGoods: '/item/tb/activity/materials/query?',               // 淘宝类目物料商品分页查询
  taskIndex: '/rebate/task/index',               // 红包主页
  taskRecord: '/rebate/task/record/list',               // 红包主页现金记录
  taskShare: '/rebate/task/share/list',               // 红包主页到账晒单
  taskWithdraw: '/rebate/task/confirm',               // 红包提现
  taskChoose: '/rebate/task/choose/page',               // 红包主页前置判断
  taskOpen: '/rebate/task/open',               // 领取红包
  //  助力信息
  taskSupport: '/rebate/task/support',
  //  点击助力
  taskBoost: '/rebate/task/boost',
  //  生成分享链接(携带友盟参数)
  taskShareUrl: '/rebate/task/init/share/url',
  //  拉起微信支付(浏览器环境)
  wxPay: '/pay/pre',
  //  查询投放的基本信息(金额,支付后下载app的跳转页面(类似于拉新红包)等)
  baseInfo: '/coupon/info/template/info',
  // 检查是否开启过优惠特权
  privilegeCheck: '/coupon/info/privilege/check',
  // 微信环境下打开链接
  innerWXPay: '/wx/oauth2/authorize',
  // 查看中奖码
  winningCode: '/lucky/code',
  // 查询抽奖商品列表
  luckyGrawGoodsList: '/lucky/draw/item/list',
  // 查询抽奖抢购记录
  purchaseRecord: '/lucky/draw/record/list',
  // 查询活动权益
  luckyDrawRights: '/lucky/draw/rights',
  // 查询开奖详情
  luckyDrawDetail: '/lucky/draw/pub/detail',
  // 未中奖退款
  recordRefund: '/lucky/draw/record/refund',
  // 未中奖，兑换礼金
  recordExchange: '/lucky/draw/record/exchange/bonus',
  //进入一元购活动页，查询需要弹出的抽奖码
  drawPopCode: '/lucky/draw/code/pop/info',
  //弹出抽奖码之后，更新弹出标记
  popUpdate: '/lucky/draw/code/popstatus/update',
  //离开一元购，查询判断弹窗类型
  drawPreBack: '/lucky/draw/pre/back',
  //礼金活动列表查询
  cashGoodsList: '/item/activity/bonus/query',
  //活动名称价格集合
  cashActivityInterval: '/bonus/activity',
  //礼金明细
  cashDetails: '/bonus/detail/list',
  //红包余额
  cashBalance: '/bonus',
  //礼金活动----点击商品获取跳转链接
  cashSend: '/item/bonus/send/v3',
  // 券列表
  couponInfoList: '/coupon/info/list',
  // 使用券 锁定
  couponUsedLock: '/coupon/info/recharge/url',
  //红包膨胀邀请链接
  inviteInfo: '/coupon/info/invite',
  // 推广位列表查询
  categoryList: '/position/info/category/v2',
  // 带二级分类的推广位查询
  categoryIcon: '/position/info/category/icon',
  // 投放页面下载app点击统计上报
  clickUpload: '/coupon/info/click/statistic',
  // 投放页面举报反馈
  putFeedback: '/coupon/info/feedback'
}
