<template>
  <div>
    <div class="mark" v-if="!click">
      <van-loading color="#fffff" type="spinner" vertical>加载中...</van-loading>
    </div>
    <!--    <img class="back" @click="back" src="https://cdn.cailu88.com/jingxuanshi/arrow_back%402x.png" alt="">-->
    <div class="elm-cashback" v-if="type == 1">
      <img class="bg" src="https://cdn.cailu88.com/jingxuanshi/elm_cashback_app.png?v=2"/>
      <div class="shadow top">
        <div class="main-top">
          <div class="steps">
            <div class="step">
              <img class="step-img" mode="scaleToFill" src="https://cdn.cailu88.com/jingxuanshi/elm_step1.png"/>
              <span class="step-text">先领券</span>
            </div>
            <img mode="scaleToFill" src="https://cdn.cailu88.com/jingxuanshi/takeout_arrow_right.png" class="arrow"/>
            <div class="step">
              <img class="step-img" mode="scaleToFill" src="https://cdn.cailu88.com/jingxuanshi/elm_step2.png"/>
              <span class="step-text">去下单</span>
            </div>
            <img mode="scaleToFill" src="https://cdn.cailu88.com/jingxuanshi/takeout_arrow_right.png" class="arrow"/>
            <div class="step">
              <img class="step-img" mode="scaleToFill" src="https://cdn.cailu88.com/jingxuanshi/elm_step3.png"/>
              <span class="step-text">拿返利</span>
            </div>
          </div>
          <van-button type="primary" class="go-btn" @click="isAuth">
            领取外卖红包
          </van-button>
        </div>
      </div>
      <div class="shadow bottom">
        <div class="main-bottom">
          <div class="title">
            分享红包赚佣金
          </div>
          <span class="content">
            好友使用您分享的口令进行下单
            <br/>
            您将享有佣金奖励
          </span>
          <div class="btns">
<!--            <button type="primary" class="share-btn" @click="isAuth('share')" style="margin-right: 10px;width: 135px">分享小程序</button>-->
            <van-button @click="copyText($event)" class="copy-btn" type="primary" style="width: 135px">
              复制红包口令
            </van-button>
          </div>
          <span class="gray">好友复制红包口令后，打开手机淘宝即可领取</span>
        </div>
      </div>
      <div class="empty-box"></div>
    </div>
    <div class="vegetables" v-if="type == 2">
      <img mode="scaleToFill" class="bg" src="https://cdn.cailu88.com/jingxuanshi/elmVegetables_app.png?v=1"/>
      <van-button type="primary" @click="isAuth" class="go">
        领取外卖红包
      </van-button>
      <div class="main">
        <div class="title">
          分享红包赚佣金
        </div>
        <span class="content">
          好友使用您分享的口令进行下单
          <br/>
          您将享有佣金奖励
        </span>
        <van-button @click="copyText($event)" class="copy-btn" type="primary">
          复制饿了么红包口令
        </van-button>
        <span class="gray">好友复制红包口令后，打开手机淘宝即可领取</span>
      </div>
      <div class="empty-box"></div>
    </div>
    <div class="mt" v-if="type == 3">
      <img mode="scaleToFill" class="bg" src="https://cdn.cailu88.com/jingxuanshi/mt_bg.jpg"/>
      <div class="step">
        <img  mode="scaleToFill"  src="https://cdn.cailu88.com/jingxuanshi/mt_step.png" alt="">
        <van-button class="go" @click="handleTransfer($event,'go')">领取外卖红包</van-button>
      </div>
      <div class="main-box">
        <p class="title">
          分享红包赚佣金
        </p>
        <p class="content">好友使用您分享的链接下单
          <br>
          您都可享有佣金奖励</p>
        <div class="btns">
          <!-- <van-button @click="handleTransfer($event,'share_mini')" style="margin-right: 10px;width: 140px">分享海报</van-button> -->
          <van-button @click="shareLink($event,'share_link')">分享红包链接</van-button>
        </div>
        <p class="tips">注：好友打开美团红包链接后即可领取</p>
      </div>
    </div>
    <van-action-sheet v-model="sheetShow" class="energy-sheet">
      <div class="sheet-top">
        <p class="title-1">分享小程序</p>
        <van-icon class="sheet-close" name="cross" @click="handleCloseSheet" />
      </div>
      <div class="sheet-posters">
        <canvas v-show='false' id="canvas" width="200" height="300"></canvas>
        <img id="bgImg" class="posters-bg" src="https://cdn.cailu88.com/jingxuanshi/client_elm_posters.png"/>
        <div class="posters-qrcode">
          <img id="qrcode" :src="qrcodeUrl"/>
        </div>
      </div>
      <div class="sheet-footer">
        <van-button @click="sharePosters">分享小程序</van-button>
        <!-- <img style="margin-left: 22px" src="https://cdn.cailu88.com/jingxuanshi/client_elm_share.png" />
        <img style="margin-right: 22px" src="https://cdn.cailu88.com/jingxuanshi/client_elm_save.png" /> -->
      </div>
      <!-- <div class="sheet-footer" style="margin-top: 10px">
        <span style="margin-left: 8px" @click="sharePosters">分享微信好友</span>
        <span style="margin-right: 14px" @click="savePosters">保存到手机</span>
      </div> -->
    </van-action-sheet>
  </div>
</template>

<script>
  import { Loading, Toast, Button, ActionSheet, Icon } from 'vant'
  import Vue from 'vue'
  import baseUrl from '../../../utils/baseUrl'
  import clipboard from '../../../utils/copy'

  Vue.use(Loading)
  Vue.use(Toast)
  Vue.use(Button)
  Vue.use(Icon)
  Vue.use(ActionSheet)
  const api = require('../../../utils/api').api
  export default {
    name: 'takeout-details',
    data () {
      return {
        isIOSApp: false,  // ios终端
        isAndroiAppd: false,
        sheetShow: false,
        qrcodeUrl: '',
        type: 1,
        token: '',
        version: '',
        channel: '',
        appClient: '',
        message: '',
        share_mt_url: '',
        clickUrl: '',
        click: true,
        mt_app_url: '',
        mt_url: '',
        url: '',
        authStatus: false
      }
    },
    mounted () {
      window.scrollTo(0, 0)
      this.type = this.$route.query.type;
      this.token = this.$route.query.token || '';
      this.version = this.$route.query.version || '';
      this.channel = this.$route.query.channel || '';
      this.appClient = this.$route.query.appClient || '';
    },
    methods: {

      // 饿了么外卖分享小程序
      sharePosters() {
        let param = {
          type: 'image',
          imageUrl: ''
        }
        let event = 'share';
        let canvas = document.getElementById('canvas')
        let context = canvas.getContext('2d')
        context.translate(0.5, 0.5);
        let eleBgImg = document.getElementById('bgImg')
        let eleQrcode = document.getElementById('qrcode')
        let imgUpload = new Image()
        imgUpload.crossOrigin = 'Anonymous'             //允许跨域
        eleQrcode.crossOrigin = 'Anonymous'
        imgUpload.src = eleBgImg.src

        imgUpload.onload = () => {
          // 绘制
          context.drawImage(imgUpload, 0, 0, 200, 300);
          // 再次绘制
          context.drawImage(eleQrcode, 70, 202, 62, 62);
          // 回调
          param.imageUrl = canvas.toDataURL('image/png');
          this.$h5AppLink(event, param)
        }
      },
      shareLink(event, type) {
        let that = this;
        if (!this.click) {
          return false;
        }
        this.click = false;
        this.$.ajax({
          url: baseUrl.baseUrl + api.transfer,
          type: 'post',
          headers: {
            'token' : that.token,
            'version' : that.version,
            'channel': that.channel,
            'appClient': that.appClient,
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: JSON.stringify({
            type: that.type,
            shareScene: true,
            clientType: 2
          }),
          async: false,
          success: function (res_t) {
            if (res_t.code !== 'USER_UN_LOGIN' && res_t.code) {
              that.click = true;
              Toast.success({
                duration: 500, // 持续展示 toast
                message: '复制成功'
              })
              that.share_mt_url = res_t.data.shareContent
            } else {
              that.click = true
              that.$h5AppLink('login', {
                backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
              })
            }
          }
        })
        clipboard(this.share_mt_url, event)
      },
      // 转链
      handleTransfer (event, type) {
        if (!this.click) {
          return false;
        }
        this.click = false;
        if(this.mt_app_url && type == 'go') {
          this.click = true;
          this.$h5AppLink('openApp', {
            url: this.mt_app_url,
            webUrl: this.mt_url
          })
          return false;
        }
        this.$axios.post(api.transfer, {
          type: 3,
          clientType: 2
        }).then(res => {
          this.click = true;
          this.mt_app_url = res.data.data.appSpreadUrl;
          this.mt_url = res.data.data.spreadUrl;
          this.$h5AppLink('openApp', {
            url: res.data.data.appSpreadUrl,
            webUrl: res.data.data.spreadUrl
          })

        }).catch(err => {
          this.click = true;
          if (err.data.code == 'USER_UN_LOGIN') {
            this.$h5AppLink('login', {
              backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
            })
          }
        })
      },
      copyText (event) {
        let that = this
        if (!this.click) {
          return false;
        }
        this.click = false;
        this.$.ajax({
          url: baseUrl.baseUrl + api.auth,
          type: 'get',
          headers: {
            'token' : this.token,
            'version' : this.version,
            'channel': this.channel,
            'appClient': this.appClient,
            'Content-Type': 'application/json;charset=UTF-8'
          },
          async: false,
          success: function (res) {
            if(res.code !== 'USER_UN_LOGIN' && res.code) {
              if (res.data.authStatus == 0) {
                that.click = true;
                that.$h5AppLink('openTbAuth', {
                  authUrl: res.data.authUrl,
                  backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
                })
              } else {
                that.$.ajax({
                  url: baseUrl.baseUrl + api.transfer,
                  type: 'post',
                  headers: {
                    'token' : that.token,
                    'version' : that.version,
                    'channel': that.channel,
                    'appClient': that.appClient,
                    'Content-Type': 'application/json;charset=UTF-8'
                  },
                  data: JSON.stringify({
                    type: that.type,
                    shareScene: true,
                    clientType: 2
                  }),
                  async: false,
                  success: function (res_t) {
                    that.click = true;
                    Toast.success({
                      duration: 500, // 持续展示 toast
                      message: '复制成功'
                    })
                    that.message = res_t.data.shareContent
                  }
                })
              }
            } else {
              that.click = true;
              that.$h5AppLink('login', {
                backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
              })
              return false;

            }

          }
        })
        this.click = true;
        clipboard(this.message, event)
      },
      back () {
        this.$router.go(-1)
      },
      // 判断是否授权
      isAuth (type) {
        let that = this
        if (!this.click) {
          return false
        }
        this.click = false
        this.$axios(api.auth, {}).then(res => {
          if (res.data.data.authStatus == 0) {
            this.click = true
            this.$h5AppLink('openTbAuth', {
              authUrl: res.data.data.authUrl,
              backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
            })
          } else {
            this.authStatus = true
            if (this.clickUrl && type != 'copy' && type != 'share') {
              this.click = true
              let paramObj = {
                version: this.version,
                url: this.clickUrl,
                backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
              }
              this.$h5AppLink('version', paramObj)
              return false
            }
            if (this.qrcodeUrl && type == 'share') {
              this.click = true
              this.sheetShow = true
              return
            }
            this.$axios.post(api.transfer, {
              type: this.type,
              shareScene: true,
              clientType: 2
            }, {}).then(res => {
              if (type == 'share') {
                this.click = true
                this.sheetShow = true
                this.qrcodeUrl = res.data.data.qrcodeUrl
              } else {
                this.click = true
                this.clickUrl = res.data.data.clickUrl
                let paramObj = {
                  version: this.version,
                  url: this.clickUrl,
                  backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
                }
                this.$h5AppLink('version', paramObj)
              }
            }).catch(err => {
              this.click = true
            })
          }
        }).catch(err => {
          this.click = true
          if (err.data.code == 'USER_UN_LOGIN') {
            this.$h5AppLink('login', {
              backUrl: `${baseUrl.pageUrl}/takeout/details?type=${this.type}`
            })

          }
        })
      },
      handleCloseSheet() {
        this.sheetShow = false
      }
    }
  }
</script>

<style scoped lang="less">

  .mark {
    width: 140px;
    height: 120px;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 20;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
  }

  .mark .van-loading__text {
    color: white !important;
  }

  .back {
    position: absolute;
    top: 14px;
    left: 15px;
    width: 16px;
    height: 16px;
    z-index: 2;
  }
  .mt {
    background: #FFCF3E;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    .bg {
      width: 375px;
      height: 247px;
    }
    .step {
      width: 356px;
      height: 190px;
      margin-top: -38px;
      position: relative;
      img {
        width:100%;
        height: 100%;
      }
      .go {
        width: 250px;
        height: 50px;
        background: #FFC200;
        border-radius: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom:34px;
        border:none;
        outline: none;
      }
    }
    .main-box {
      width: 356px;
      height: 202px;
      background: url("https://duilu2018.oss-cn-hangzhou.aliyuncs.com/jingxuanshi/mt_text_bg.png");
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-top: 20px;
        height: 25px;
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #FFC200;
        line-height: 25px;
      }
      .content {
        width: 243px;
        height: 42px;
        font-size: 15px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        text-align: center;
        color: #333333;
        line-height: 21px;
        margin-top: 5px;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        margin: 13px 0;
        button {
          width: 284px;
          height: 45px;
          border-radius: 25px;
          border: 1px solid #FFC200;
          font-size: 15px;
          font-weight: bold;
          color: #FFC200;
          line-height: 21px;
          text-align: center;
          line-height: 45px;
          background: #ffffff;
        }
      }
      .tips {
        height: 17px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
    }
  }
  .energy-sheet {
    padding-top: 20px;
    padding-bottom: 26px;
    text-align: center;
    color: #333333;
    .sheet-top{
      position: relative;
      margin: 0 auto;
      .title-1{
        font-size: 18px;
        font-weight: bold;
      }
      .sheet-close{
        position: absolute;
        top: 0;
        right: 20px;
        font-size: 18px;
        color: #000000;
      }
    }
    .sheet-posters{
      position: relative;
      margin: 0 auto;
      margin-top: 16px;
      margin-bottom: 18px;
      width: 200px;
      .posters-bg{
        width: 200px;
      }
      .posters-qrcode{
        position: absolute;
        padding: 2px;
        background: #FFFFFF;
        border-radius: 5px;
        top: 200px;
        left: 68px;
        img{
          width: 62px;
        }
      }
    }
    .sheet-footer{
      text-align: center;
      .van-button{
        width: 240px;
        height: 45px;
        background: #028DFF;
        border-radius: 25px;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .elm-cashback {
    background: #1553C9;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
  }

  .elm-cashback .bg {
    width: 375px;
    height: 274px;
  }

  .elm-cashback .shadow {
    width: 340px;
    background: #0F41A1;
    border-radius: 10px;
    position: absolute;
  }

  .top {
    left: 50%;
    transform: translateX(-50%);
    top: 250px;
    height: 166px;
  }

  .bottom {
    left: 50%;
    transform: translateX(-50%);
    top: 444px;
    height: 193px;
  }

  .elm-cashback .main-top {
    width: 320px;
    height: 172px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    left: 10px;
    top: -16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding-top: 24px;
    font-size: 15px;
  }

  .elm-cashback .main-top .steps {
    display: flex;
    align-items: center;
  }

  .elm-cashback .main-top .steps .arrow {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }

  .elm-cashback .main-top .steps .step {
    display: flex;
    flex-direction: column;
  }

  .elm-cashback .main-top .steps .step .step-img {
    width: 48px;
    height: 48px;
  }

  .elm-cashback .main-top .steps .step .step-text {
    font-size: 13px;
    color: #028DFF;
  }

  .elm-cashback .main-top .go-btn {
    width: 250px;
    height: 50px;
    background: #028DFF;
    box-shadow: 0px 3px 5px 1px rgba(0, 143, 254, 0.3);
    border-radius: 25px;
    margin-top: 16px;
    line-height: 50px;
    outline: none;
    color: white;
    border: none;

    font-size: 18px;
  }

  .elm-cashback .main-bottom {
    width: 320px;
    height: 201px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    left: 10px;
    top: -16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding-top: 21px;
    font-size: 15px;
  }

  .elm-cashback .main-bottom .title {
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    color: #028DFF;
    line-height: 25px;
  }

  .elm-cashback .main-bottom .content {
    height: 42px;
    color: #333333;
    line-height: 21px;
  }

  .elm-cashback .main-bottom .btns {
    display: flex;
    width: 320px;
    padding: 0 16px;
    box-sizing: border-box;
    justify-content: center;
  }

  .elm-cashback .main-bottom .copy-btn, .elm-cashback .main-bottom .share-btn {
    width: 250px;
    height: 45px;
    border-radius: 25px;
    border: 1px solid #028DFF;
    background: white;
    margin: 12px 0;
    color: #028DFF;
    line-height: 45px;
    outline: none;
    font-size: 15px;
  }


  .elm-cashback .main-bottom .gray {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
  }

  .elm-cashback .empty-box {
    width: 375px;
    height: 383px;
  }


  .vegetables {
    background: #028DFF;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
  }

  .vegetables .bg {
    width: 375px;
    height: 498px;
  }

  .vegetables .go {
    width: 250px;
    height: 50px;
    background: #028DFF;
    box-shadow: 0px 3px 5px 1px rgba(0, 143, 254, 0.3);
    border-radius: 29px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    top: 218px;
    outline: none;
    border: none;
    color: white;
  }

  .vegetables .main {
    width: 320px;
    height: 186px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    bottom: 33px;
    left: 50%;
    top: 428px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding-top: 14px;
    font-size: 15px;
  }

  .vegetables .main .title {
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    color: #028DFF;
    line-height: 25px;
  }

  .vegetables .main .content {
    height: 42px;
    color: #333333;
    line-height: 21px;
  }

  .vegetables .main .copy-btn {
    width: 250px;
    height: 45px;
    border-radius: 25px;
    border: 1px solid #028DFF;
    background: white;
    margin: 12px 0;
    color: #028DFF;
    line-height: 45px;
    outline: none;
    font-size: 15px;
  }

  .vegetables .main .gray {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
  }

  .vegetables .empty-box {
    width: 375px;
    height: 126px;
  }
</style>
